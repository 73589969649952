import React, { useEffect, useRef } from 'react'
import gsap from 'gsap';
import { ScrollSmoother } from 'gsap/all';
import Header from './Components/Header'
import Footer from './Layout/Footer'
import FormSection from './Layout/FormSection'
import Hero from './Layout/Hero'
import PackagesSection from './Layout/PackagesSection'
import PortfolioSection from './Layout/PortfolioSection'
import ReviewSection from './Layout/ReviewSection'
import ServicesSection from './Layout/ServicesSection'

function App() {
  // gsap.registerPlugin(ScrollSmoother);

  // useEffect(() => {
  //   let smoother = ScrollSmoother.create({
  //     smooth: 1,
  //   });
  //   return () => {
  //     smoother.kill();
  //   };
  // }, []);
  
  return (
    <div id="smooth-wrapper">
      <div id="smooth-content">
        <Header />
        <Hero />
        <dl style={{ marginBottom: '285px' }} />
        <ReviewSection />
        <dl style={{ marginBottom: '285px' }} />
        <PackagesSection />
        <dl style={{ marginBottom: '216px' }} />
        <PortfolioSection />
        <dl style={{ marginBottom: '265px' }} />
        <ServicesSection />
        <dl style={{ marginBottom: '265px' }} />
        <FormSection />
        <dl style={{ marginBottom: '204px' }} />
        <Footer />
      </div>
    </div>
  )
}

export default App
