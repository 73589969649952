import React from 'react';
import styles from './trending.module.scss';

export default function Trending() {
  return (
    <div className={styles.trending}>
      <p className={styles.trending__title}>Trending:</p>
      <div className={styles.trending__container}>
        <div className={styles.trending__item}>
          <div className={styles.trending__box}>
            <img src="./images/branding.png" alt="" />
          </div>
          <p className={styles.trending__text}>Branding design</p>
        </div>
        <div className={styles.trending__item}>
          <div className={styles.trending__box}>
            <img src="./images/logo-design.png" alt="" />
          </div>
          <p className={styles.trending__text}>Logo & website</p>
        </div>
        <div className={styles.trending__item}>
          <div className={styles.trending__box}>
            <img src="./images/website-builder.png" alt="" />
          </div>
          <p className={styles.trending__text}>Website builders</p>
        </div>
        <div className={styles.trending__item}>
          <div className={styles.trending__box}>
            <img src="./images/branding-ideas.png" alt="" />
          </div>
          <p className={styles.trending__text}>Logo & brand id...</p>
        </div>
        <div className={styles.trending__item}>
          <div className={styles.trending__box}>
            <img src="./images/production-design.png" alt="" />
          </div>
          <p className={styles.trending__text}>Product packagi...</p>
        </div>
        <div className={styles.trending__item}>
          <div className={styles.trending__box}>
            <img src="./images/illustration.png" alt="" />
          </div>
          <p className={styles.trending__text}>Illustration or gr...</p>
        </div>
        <div className={styles.trending__item}>
          <div className={styles.trending__box}>
            <img src="./images/book-cover.png" alt="" />
          </div>
          <p className={styles.trending__text}>Book cover</p>
        </div>
        <div className={styles.trending__item}>
          <div className={styles.trending__box}>
            <img src="./images/t-shirt.png" alt="" />
          </div>
          <p className={styles.trending__text}>T-shirt</p>
        </div>
        <div className={styles.trending__item}>
          <div className={styles.trending__box}>
            <img src="./images/show-more.png" alt="" />
          </div>
          <p className={styles.trending__text}>Show more</p>
        </div>
      </div>
    </div>
  )
}
