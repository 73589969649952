import React, { useEffect, useRef } from 'react'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { Row, Col } from 'react-bootstrap'
import styles from './../tabs.module.scss'

export default function TabContent() {
  gsap.registerPlugin(ScrollTrigger);
  const container = useRef<HTMLDivElement>(null);
  const secondContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    
    gsap.to(container.current, {
      x: () => -(container.current.scrollWidth - document.documentElement.clientWidth) + "px",
      ease: "none",
      scrollTrigger: {
        trigger: container.current,
        scrub: 1,
        end: () => "+=" + container.current.offsetWidth
      }
    })

    gsap.to(secondContainer.current, {
      x: () => -(document.documentElement.clientWidth - secondContainer.current.scrollWidth) + "px",
      ease: "none",
      scrollTrigger: {
        trigger: secondContainer.current,
        scrub: 1,
        end: () => "+=" + secondContainer.current.offsetWidth
      }
    })
  }, []);
  
  return (
    <div className='d-flex flex-column justify-content-center align-items-center mt-5 pt-4 overflow-hidden'>
      <div className={styles.content__imgcontainer} ref={container}>
        <div className={styles.content__img}>
          <div></div>
          <img className='' src="./images/2.png" alt="" />
        </div>
        <div className={styles.content__img}>
          <div></div>
          <img className='' src="./images/3.png" alt="" />
        </div>
        <div className={styles.content__img}>
          <div></div>
          <img className='' src="./images/4.png" alt="" />
        </div>
        <div className={styles.content__img}>
          <div></div>
          <img className='' src="./images/5.png" alt="" />
        </div>
        <div className={styles.content__img}>
          <div></div>
          <img className='' src="./images/7.png" alt="" />
        </div>
      </div>
      <div className={styles.content__imgcontainer} ref={secondContainer} style={{transform: 'translateX(-50%)'}}>
        <div className={styles.content__img}>
          <div></div>
          <img className='' src="./images/2.png" alt="" />
        </div>
        <div className={styles.content__img}>
          <div></div>
          <img className='' src="./images/3.png" alt="" />
        </div>
        <div className={styles.content__img}>
          <div></div>
          <img className='' src="./images/4.png" alt="" />
        </div>
        <div className={styles.content__img}>
          <div></div>
          <img className='' src="./images/5.png" alt="" />
        </div>
        <div className={styles.content__img}>
          <div></div>
          <img className='' src="./images/7.png" alt="" />
        </div>
      </div>
    </div>
  )
}
