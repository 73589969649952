import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import TabContent from './TabContent';

export default function PackageTabs() {

  return (
    <Tabs
      defaultActiveKey='LOGO'
      className="mb-3 justify-content-center"
    >
      <Tab eventKey="LOGO" title="LOGO">
        <TabContent />
      </Tab>
      <Tab eventKey="BRANDING" title="BRANDING">
      <TabContent />
      </Tab>
      <Tab eventKey="WEBSITE" title="WEBSITE">
      <TabContent />
      </Tab>
      <Tab eventKey="SEO" title="SEO">
        <TabContent />
      </Tab>
      <Tab eventKey="SOCIAL MEDIA" title="SOCIAL MEDIA">
        <TabContent />
      </Tab>
    </Tabs>
  );  
}
