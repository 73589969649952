import React from 'react'
import { Container } from 'react-bootstrap'
import styles from './footer.module.scss'

export default function Footer() {
  return (
    <Container>
      <div className={`${styles.footer__img} d-flex justify-content-center`} style={{ marginBottom: '220px'}}>
        <img src="./images/logo-3.png" alt="" />
      </div>
      <div className='d-flex justify-content-between mb-5'>
        <div>
          <p className='fw-bold'>Let's work together</p>
          <p className='mb-1'>Start a project</p>
          <p>bussiness@humaan.com</p>
        </div>
        <div>
          <p className='fw-bold'>Speak to us</p>
          <p className='mb-1'>(08) 9471 7645</p>
          <p>hello@humaan.com</p>
        </div>
        <div>
          <p className='fw-bold'>Headquaters</p>
          <p className='mb-1'>470 William Street</p>
          <p>Perth, Australia 11&#176;</p>
        </div>
      </div>
    </Container>
  )
}
