import React from 'react'
import { Container } from 'react-bootstrap'
import styles from './../../Components/Tabs/tabs.module.scss';
import PackageTabs from '../../Components/Tabs/PackageTabs'

export default function PackagesSection() {
  return (
    <Container>
      <div className='d-flex flex-column align-items-center mb-4'>
        <h1 className={styles.tabs__heading}>Pricing Packages That Suit Your Budget</h1>
        <p className={`${styles.tabs__desc} text-center`}>
          Whether you are a startup looking for a simple logo or a large enterprise in quest of a 
          complete digital marketing solution, we make sure our packages are accommodating all your 
          needs, demands and desires.
        </p>
      </div>
      <PackageTabs />
    </Container>
  )
}
