import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import TabContent from './TabContent'

export default function PortfolioTabs() {
  return (
    <Tabs
      defaultActiveKey='ALL'
      className="mb-3 justify-content-center primary"
    >
      <Tab eventKey="ALL" title="ALL">
        <TabContent />
      </Tab>
      <Tab eventKey="BRANDING" title="BRANDING">
        <TabContent />
      </Tab>
      <Tab eventKey="WEBSITE" title="WEBSITE">
        <TabContent />
      </Tab>
      <Tab eventKey="SEO" title="SEO">
        <TabContent />
      </Tab>
      <Tab eventKey="SOCIAL MEDIA" title="SOCIAL MEDIA">
        <TabContent />
      </Tab>
    </Tabs>
  )
}
