import React from 'react'
import { Container } from 'react-bootstrap'
import styles from './../../Components/Tabs/tabs.module.scss';
import PackageTabs from '../../Components/Tabs/PackageTabs'
import PortfolioTabs from '../../Components/Tabs/PortfolioTabs';

export default function PortfolioSection() {
  return (
    <Container fluid>
      <div className='d-flex flex-column align-items-center mb-4'>
        <h1 className={styles.tabs__heading}>Masterworks We Have Created</h1>
        <p className={`${styles.tabs__desc} text-center`}>
        We have worked alongside our customers on some of the most interesting projects in the industry. Get a sneak peek at some of the work we have done for our clients across the Earth.
        </p>
      </div>
      <PortfolioTabs />
    </Container>
  )
}
