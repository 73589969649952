import React from 'react'
import { Button, Container } from 'react-bootstrap'
import Slider from '../../Components/Slider'
import styles from './reviewsection.module.scss'

export default function ReviewSection() {
  return (
    <>
      <Container>
        <h1 className={styles.review__title}>
          Logos, website, app
          design and more!
        </h1>
        <hr className={styles.review__seprator}/>
        <div className='d-flex justify-content-between'>
          <p className={styles.review__desc}>
            Get the perfect logo design - or any design in over 90 categories! Whatever
            your business need or budget, we’ll help get it done.
          </p>
          <Button style={{maxHeight: '50px', padding: '0 37px'}} variant='secondary' className='text-white'>See all design categories</Button>
        </div>
      </Container>
      <Slider />
      <div className='d-flex justify-content-center' style={{marginTop: '50px'}}>
        <img src="./images/stars.png" alt="" />
      </div>
    </>
  )
}
