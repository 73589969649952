import React from 'react'
import { Container } from 'react-bootstrap'

export default function FormSection() {
  return (
    <Container style={{maxWidth: '1000px'}}>
      <div className="row no-gutters justify-content-center align-items-top">
        <div className="col-12 col-sm-4 only-desktop">
          <h3 style={{ color: '#000', fontSize: '1rem', letterSpacing: '5px' }}>CONTACT</h3>
          <div className="qc-outro-mail mt-3">
            <a href="mailto:info@qclay.design">
              <em><span>info@vod.design</span></em>
            </a>
          </div>
          <br />
          <h3 style={{ color: '#000', fontSize: '1rem', letterSpacing: '5px' }}>FOLLOW</h3>
          <div className="mt-3">
            <a href="/"><img alt='social' className="qc-social" src="/images/tiktok.svg" /></a>
            <a href="/" className="ms-3"><img alt='social' className="qc-social" src="/images/instagram.svg" /></a>
            <a href="/" className="ms-3"><img alt='social' className="qc-social" src="/images/dribbble.svg" /></a>
          </div>
        </div>
        <div className="col-12 col-sm-8">
          <h3 style={{ color: '#000', fontSize: '1rem', letterSpacing: '5px' }}>HIRE US</h3>
          <h1>
            <div className="fixed-line mt-2">Let’s talk!</div>
          </h1>
          <form className="mt-5" id="email-form" method="POST" action="./addfile/sendsorder.php" name="email-form" data-name="Email Form">
              <div className="holder-field">
                <input type="text" className="text-field text-field1 w-input" name="Name" data-name="Name" placeholder="John from Google…" id="Name" />
                  <label className="field-label field-label1">Name &amp; Company</label>
              </div>
              <div className="holder-field">
                <input type="email" className="text-field text-field2 w-input" name="Email" data-name="Email" placeholder="john@google.com" id="Email"/>
                  <label className="field-label field-label2">Email</label>
              </div>
              <label className="field-label field-label3">Project Budget</label>
              <div className="radio-holder mt-2">
                <label className="radio-btn radio-new w-radio">
                  <input type="radio" data-name="Budget" id="$10k - $20K" name="Budget" value="$10k - $20K" className="w-form-formradioinput w-radio-input"/>
                    <span  className="btn12 w-form-label">$10k - $20K</span>
                </label>
                <label className="radio-btn radio-new w-radio">
                  <input type="radio" data-name="Budget" id="$20k - $50K" name="Budget" value="$20k - $50K" className="w-form-formradioinput w-radio-input"/>
                    <span  className="btn12 w-form-label">$20k - $50K</span>
                </label>
                <label className="radio-btn radio-new w-radio">
                  <input type="radio" data-name="Budget" id="$50k - $100K" name="Budget" value="$50k - $100K" className="w-form-formradioinput w-radio-input"/>
                    <span  className="btn12 w-form-label">$50k - $100K</span>
                </label>
                <label className="radio-btn radio-new radion-new-last w-radio">
                  <input type="radio" data-name="Budget" id="$100K+" name="Budget" value="$100K+" className="w-form-formradioinput w-radio-input"/>
                    <span className="btn12 w-form-label">$100K+</span>
                </label>
              </div>
              <div className="holder-field mt-5">
                <textarea placeholder="We need help to redesign our app…"  id="Message" name="Message" data-name="Message" className="textarea w-input"></textarea>
                <label className="field-label field-label4">Project Description</label>
              </div>
              <div className="relative-holder-sb">
                <div className="buttons">
                  <div className="button button--3">
                    <button type="submit" data-cursor-type="medium" data-cursor-text="" className="open-showreel">Send</button>
                  </div>
                </div>
              </div>
          </form>
        </div>
      </div>
    </Container>
  )
}
