import React, { useState } from 'react'
import { Container } from 'react-bootstrap';
import styles from './header.module.scss';

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className={styles.header}>
      <Container className='d-flex justify-content-between align-items-center'>
        <div className={styles.header__logo}>
          <img src="./images/logo.png" alt="" />
        </div>
        <nav className={styles.header__nav}>
          <ul className={`${styles['header__nav-container']} ${showMenu ? styles.active : ''}`}>
            <li className={styles['header__nav-item']}>
              <a href="/">
                Logo
              </a>
            </li>
            <li className={styles['header__nav-item']}>
              <a href="/">
                Web Design
              </a>
            </li>
            <li className={styles['header__nav-item']}>
              <a href="/">
                Marketing
              </a>
            </li>
            <li className={styles['header__nav-item']}>
              <a href="/">
                Services
              </a>
            </li>
            <li className={styles['header__nav-item']}>
              <a href="/">
                Portfolio
              </a>
            </li>
            <li className={styles['header__nav-item']}>
              <a href="/">
                Startups
              </a>
            </li>
            <li className={styles['header__nav-item']}>
              <a href="/">
                Contact Us
              </a>
            </li>
          </ul>
          <div className={styles.header__btn} onClick={() => {setShowMenu(!showMenu)}}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="0.5in" height="0.167in">
              <path fill-rule="evenodd" fill="rgb(0, 0, 0)"
                d="M18.156,11.812 L18.156,9.562 L35.500,9.562 L35.500,11.812 L18.156,11.812 ZM0.844,6.875 L0.844,4.625 L18.156,4.625 L35.500,4.625 L35.500,6.875 L18.156,6.875 L0.844,6.875 ZM0.844,0.125 L18.156,0.125 L18.156,2.375 L0.844,2.375 L0.844,0.125 Z" />
            </svg>
          </div>
        </nav>
      </Container>
    </div>
  )
}
