import React from 'react';
import { Button } from 'react-bootstrap';
import styles from './banner.module.scss';

export default function Banner() {
  return (
    <div className={`d-flex align-items-center ${styles.banner}`}>
      <div className={styles.banner__content}>
        <h1 className={styles.banner__title}>
          Branding, websites,
          and UI+UX
        </h1>
        <div className='d-flex mb-4'>
          <div className='position-relative w-100'>
            <svg
              width="17" height="17" className={styles['banner__input-icon']}>
              <path fillRule="evenodd" opacity="0.502" fill="rgb(0, 0, 0)"
                d="M15.288,15.613 C14.924,15.982 14.335,15.982 13.971,15.613 L11.089,12.730 C9.997,13.549 8.639,14.035 7.169,14.035 C3.557,14.035 0.630,11.103 0.630,7.488 C0.630,3.872 3.557,0.940 7.169,0.940 C10.780,0.940 13.707,3.872 13.707,7.488 C13.707,8.955 13.226,10.306 12.414,11.398 L15.288,14.277 C15.652,14.646 15.652,15.243 15.288,15.613 ZM7.169,2.811 C4.589,2.811 2.498,4.905 2.498,7.488 L2.499,7.488 C2.499,10.071 4.590,12.164 7.170,12.164 C9.749,12.164 11.839,10.069 11.839,7.488 C11.839,4.904 9.749,2.811 7.169,2.811 Z" />
            </svg>
            <input type="text" placeholder='Logo, website, branding...' className={styles.banner__input} />
          </div>
          <Button variant='success' className='text-nowrap px-4 fw-semibold ms-4'>Get started</Button>
        </div>
        <div className='d-flex align-items-center'>
          <svg
            width="20" height="20" className='me-2'>
            <path fillRule="evenodd" fill="rgb(54, 197, 241)"
              d="M10.297,0.000 C15.449,0.000 19.625,4.184 19.625,9.344 C19.625,14.504 15.449,18.688 10.297,18.688 C5.145,18.688 0.969,14.504 0.969,9.344 C0.969,4.184 5.145,0.000 10.297,0.000 Z" />
          </svg>
          <p className='text-secondary fw-semibold mb-0'>See Creativity at work</p>
        </div>
      </div>
      <img src="./images/banner.png" className={styles.banner__img} alt="" style={{ marginRight: '-105px' }} />
    </div>
  )
}