import React from 'react'
import Banner from '../../Components/Banner'
import Trending from '../../Components/Trending'

export default function Hero() {
  return (
    <div className='container'>
      <Banner />
      <p style={{marginBottom: '100px'}} />
      <Trending />
    </div>
  )
}
