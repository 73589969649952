import React, { useEffect } from 'react'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { Col, Container, Row } from 'react-bootstrap'
import styles from './services.module.scss'

export default function ServicesSection() {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    let revealContainers = document.querySelectorAll(".reveal");
    let textContainers = document.querySelectorAll(".services-container");

    revealContainers.forEach((container) => {
      let image = container.querySelector("img");
      let box = container.querySelector(".reveal-box")

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          start: 'top 70%',
          toggleActions: "restart none none reverse"
        }
      });

      tl.fromTo(box, { xPercent: -100 }, { duration: 1, xPercent: 0, ease: 'power4' })
      tl.fromTo(image, { xPercent: 100, delay: -1.5 }, { duration: 1, xPercent: 0, ease: 'power4' }, "<")
    });

    textContainers.forEach((container) => {
      let text = container.querySelector('.services-text')
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          start: '-40 center',
          end: 'bottom center',
          onEnterBack: () => {
            tl.to(text, { opacity: 1, duration: 0.4})
          },
          onEnter: () => {
            tl.to(text, { opacity: 1, duration: 0.4})
          },
          onLeave: () => {
            tl.to(text, { opacity: 0, duration: 0.4 })
          },
          onLeaveBack: () => {
            tl.to(text, { opacity: 0, duration: 0.4 })
          }
        }
      });
    });
  }, []);

  return (
    <>
      <Container>
        <div className='d-flex justify-content-between mb-4'>
          <h1 className={styles.services__heading}>Want to get your <br />
            business started?
          </h1>
          <h1 className={styles.services__heading}>Try these few Steps!</h1>
        </div>
      </Container>
      <Container>
        <Row style={{ marginBottom: '60px' }}>
          <Col>
            <div className={`reveal ${styles.app__imgcontainer}`}>
              <div className={`reveal-box ${styles.app__img}`}>
                <img src="./images/services-2.png" alt="" />
              </div>
            </div>
          </Col>
          <Col>
            <div className={`${styles['services__text-container']} ${styles['services__text-container--first']} services-container`}>
              <div className={`${styles.services__text} ${styles['services__text--first']} services-text`} style={{opacity: 1}}>
                <p>Technology</p>
                <h2>
                  Headless Shopify with wearables for creative musicians 1
                </h2>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: '60px' }}>
          <Col>
            <div className={`reveal ${styles.app__imgcontainer}`}>
              <div className={`reveal-box ${styles.app__img}`}>
                <img src="./images/services.png" alt="" />
              </div>
            </div>
          </Col>
          <Col>
            <div  className={`${styles['services__text-container']} services-container`}>
              <div className={`${styles.services__text} services-text`}>
                <p>Technology</p>
                <h2>
                  Headless Shopify with wearables for creative musicians 2
                </h2>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: '60px' }}>
          <Col>
            <div className={`reveal ${styles.app__imgcontainer}`}>
              <div className={`reveal-box ${styles.app__img}`}>
                <img src="./images/services-2.png" alt="" />
              </div>
            </div>
          </Col>
          <Col>
            <div  className={`${styles['services__text-container']} services-container`}>
              <div className={`${styles.services__text} services-text`}>
                <p>Technology</p>
                <h2>
                  Headless Shopify with wearables for creative musicians 3
                </h2>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: '60px' }}>
          <Col>
            <div className={`reveal ${styles.app__imgcontainer}`}>
              <div className={`reveal-box ${styles.app__img}`}>
                <img src="./images/services.png" alt="" />
              </div>
            </div>
          </Col>
          <Col>
            <div  className={`${styles['services__text-container']} ${styles['services__text-container--last']} services-container`}>
              <div className={`${styles.services__text} services-text`}>
                <p>Technology</p>
                <h2>
                  Headless Shopify with wearables for creative musicians 4
                </h2>
              </div>
            </div>
          </Col>
        </Row>
        <div className='d-flex justify-content-center'>
          <img src="./images/brands.png" alt="" />
        </div>
      </Container>
    </>
  )
}
