import React from 'react'
import { Badge } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styles from './slider.module.scss';

export default function Slider() {
  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlay
      autoPlaySpeed={2000}
      centerMode={false}
      className=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      customLeftArrow={<span />}
      customRightArrow={<span />}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024
          },
          items: 4,
          partialVisibilityGutter: 200
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0
          },
          items: 1,
          partialVisibilityGutter: 100
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464
          },
          items: 2,
          partialVisibilityGutter: 30
        }
      }}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      sliderClass="slider-space"
      slidesToSlide={2}
      swipeable
    >
      <div className={styles.slider__item}>
        <div className={styles.slider__img}>
          <div className={styles.slider__overlay}>
            <h5>Bussiness Card</h5>
            <p>An unforgettable logo for your brand</p>
          </div>
          <img src="./images/2.png" alt="" />
          <Badge pill bg="primary" className={styles.slider__badge}>
            Logo Design
          </Badge>
        </div>
        <div className='d-flex align-items-center'>
          <div className={styles.slider__profile} >
            <img className='w-100' src="./images/dummy.jpg" alt="" />
          </div>
          <span className={styles.slider__pname}>by Wintrygrey</span>
        </div>
      </div>
      <div className={styles.slider__item}>
        <div className={styles.slider__img}>
          <div className={styles.slider__overlay}>
            <h5>Bussiness Card</h5>
            <p>An unforgettable logo for your brand</p>
          </div>
          <img src="./images/4.png" alt="" />
          <Badge pill bg="dark" className={styles.slider__badge}>
            Packaging Label
          </Badge>
        </div>
        <div className='d-flex align-items-center'>
          <div className={styles.slider__profile} >
            <img className='w-100' src="./images/dummy.jpg" alt="" />
          </div>
          <span className={styles.slider__pname}>by Wintrygrey</span>
        </div>
      </div>
      <div className={styles.slider__item}>
        <div className={styles.slider__img}>
          <div className={styles.slider__overlay}>
            <h5>Bussiness Card</h5>
            <p>An unforgettable logo for your brand</p>
          </div>
          <img src="./images/2.png" alt="" />
          <Badge pill bg="primary" className={styles.slider__badge}>
            Logo Design
          </Badge>
        </div>
        <div className='d-flex align-items-center'>
          <div className={styles.slider__profile} >
            <img className='w-100' src="./images/dummy.jpg" alt="" />
          </div>
          <span className={styles.slider__pname}>by Wintrygrey</span>
        </div>
      </div>
      <div className={styles.slider__item}>
        <div className={styles.slider__img}>
          <div className={styles.slider__overlay}>
            <h5>Bussiness Card</h5>
            <p>An unforgettable logo for your brand</p>
          </div>
          <img src="./images/5.png" alt="" />
          <Badge pill bg="primary" className={styles.slider__badge}>
            Logo & business card
          </Badge>
        </div>
        <div className='d-flex align-items-center'>
          <div className={styles.slider__profile} >
            <img className='w-100' src="./images/dummy.jpg" alt="" />
          </div>
          <span className={styles.slider__pname}>by Wintrygrey</span>
        </div>
      </div>
      <div className={styles.slider__item}>
        <div className={styles.slider__img}>
          <div className={styles.slider__overlay}>
            <h5>Bussiness Card</h5>
            <p>An unforgettable logo for your brand</p>
          </div>
          <img src="./images/7.png" alt="" />
          <Badge pill bg="secondary" className={styles.slider__badge}>
            Illustrations
          </Badge>
        </div>
        <div className='d-flex align-items-center'>
          <div className={styles.slider__profile} >
            <img className='w-100' src="./images/dummy.jpg" alt="" />
          </div>
          <span className={styles.slider__pname}>by Wintrygrey</span>
        </div>
      </div>
    </Carousel>
  )
}
