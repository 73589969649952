import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import styles from './../tabs.module.scss';

export default function TabContent() {

  let [active, setActive] = useState('basic');

  return (
    <>
    <div className={styles.content__tabs}>
      <Row>
        <Col className={`${styles['content__tab-container']} ${active === 'basic' ? styles.active : ''}`}>
          <div className={styles.content__tab} onClick={() => setActive('basic')}>
            <h2 className={styles['content__package-title']}>
              $49
            </h2>
            <p className={styles['content__package-label']}>BASIC <br/> LOGO</p>
          </div>
        </Col>
        <Col className={`${styles['content__tab-container']} ${active === 'standard' ? styles.active : ''}`}>
          <div className={styles.content__tab}  onClick={() => setActive('standard')}>
            <h2 className={styles['content__package-title']}>
              $99
            </h2>
            <p className={styles['content__package-label']}>STANDARD <br/> LOGO</p>
          </div>
        </Col>
        <Col className={`${styles['content__tab-container']} ${active === 'prime' ? styles.active : ''}`}>
          <div className={styles.content__tab}  onClick={() => setActive('prime')}>
            <h2 className={styles['content__package-title']}>
              $249
            </h2>
            <p className={styles['content__package-label']}>PRIME <br/> LOGO</p>
          </div>
        </Col>
        <Col className={`${styles['content__tab-container']} ${active === 'deluxe' ? styles.active : ''}`}>
          <div className={styles.content__tab}  onClick={() => setActive('deluxe')}>
            <h2 className={styles['content__package-title']}>
              $349
            </h2>
            <p className={styles['content__package-label']}>DELUXE <br/> LOGO</p>
          </div>
        </Col>
      </Row>
    </div>
    <Row>
      <Col>
        <h2 className={styles.content__heading}>
          <span className='text-capitalize'>{active}</span> Logo Packages
        </h2>
        <p className={styles.content__label}>For Startup & Small Businesses</p>
        <p className={styles.content__desc}>
          Our {active} logo package is best-known for part-time small
          businesses or projects on a limited budget with a quick turn around time. With this package, you can get a budget-friendly business logo design from a team of our mid-level experienced logo
          designers based on your design preference. To ensure customer satisfaction, you can review those logo design concepts and ask
          for changes in the design, colors or fonts if required.
        </p>
        <div className={styles.content__addons}>
          <span className={styles.content__addon}>Features</span>
          <span className={styles.content__addon}>What You Will Get?</span>
          <span className={styles.content__addon}>Add Ons?</span>
        </div>
        <ul className={styles.content__list}>
          <li>
            <svg
              width="15" height="13">
              <path fillRule="evenodd" fill="rgb(0, 0, 0)"
                d="M4.999,12.001 C5.399,12.001 5.777,11.843 6.060,11.557 L15.707,1.725 C16.098,1.329 16.098,0.691 15.707,0.296 C15.317,-0.098 14.683,-0.098 14.293,0.296 L4.999,9.773 L1.706,6.297 C1.317,5.900 0.683,5.900 0.293,6.297 C-0.097,6.691 -0.097,7.329 0.293,7.724 L3.939,11.557 C4.222,11.843 4.596,12.001 4.998,12.001 L4.999,12.001 Z" />
            </svg>
            2 Logo Design Concepts
          </li>
          <li>
            <svg
              width="15" height="13">
              <path fillRule="evenodd" fill="rgb(0, 0, 0)"
                d="M4.999,12.001 C5.399,12.001 5.777,11.843 6.060,11.557 L15.707,1.725 C16.098,1.329 16.098,0.691 15.707,0.296 C15.317,-0.098 14.683,-0.098 14.293,0.296 L4.999,9.773 L1.706,6.297 C1.317,5.900 0.683,5.900 0.293,6.297 C-0.097,6.691 -0.097,7.329 0.293,7.724 L3.939,11.557 C4.222,11.843 4.596,12.001 4.998,12.001 L4.999,12.001 Z" />
            </svg>
            Dedicated Project Manager
          </li>
          <li>
            <svg
              width="15" height="13">
              <path fillRule="evenodd" fill="rgb(0, 0, 0)"
                d="M4.999,12.001 C5.399,12.001 5.777,11.843 6.060,11.557 L15.707,1.725 C16.098,1.329 16.098,0.691 15.707,0.296 C15.317,-0.098 14.683,-0.098 14.293,0.296 L4.999,9.773 L1.706,6.297 C1.317,5.900 0.683,5.900 0.293,6.297 C-0.097,6.691 -0.097,7.329 0.293,7.724 L3.939,11.557 C4.222,11.843 4.596,12.001 4.998,12.001 L4.999,12.001 Z" />
            </svg>
            By 1 Experienced Designer
          </li>
          <li>
            <svg
              width="15" height="13">
              <path fillRule="evenodd" fill="rgb(0, 0, 0)"
                d="M4.999,12.001 C5.399,12.001 5.777,11.843 6.060,11.557 L15.707,1.725 C16.098,1.329 16.098,0.691 15.707,0.296 C15.317,-0.098 14.683,-0.098 14.293,0.296 L4.999,9.773 L1.706,6.297 C1.317,5.900 0.683,5.900 0.293,6.297 C-0.097,6.691 -0.097,7.329 0.293,7.724 L3.939,11.557 C4.222,11.843 4.596,12.001 4.998,12.001 L4.999,12.001 Z" />
            </svg>
            24 - 48 Hours Delivery
          </li>
          <li>
            <svg
              width="15" height="13">
              <path fillRule="evenodd" fill="rgb(0, 0, 0)"
                d="M4.999,12.001 C5.399,12.001 5.777,11.843 6.060,11.557 L15.707,1.725 C16.098,1.329 16.098,0.691 15.707,0.296 C15.317,-0.098 14.683,-0.098 14.293,0.296 L4.999,9.773 L1.706,6.297 C1.317,5.900 0.683,5.900 0.293,6.297 C-0.097,6.691 -0.097,7.329 0.293,7.724 L3.939,11.557 C4.222,11.843 4.596,12.001 4.998,12.001 L4.999,12.001 Z" />
            </svg>
            4 Free Revisions
          </li>
        </ul>
      </Col>
      <Col>
          <div className={styles.content__imghead}>
            <div className={styles.content__leftimg}>
              <img src="./images/layer-32.png" alt="" />
            </div>
            <div className={styles.content__sideimg}>
                    <div className={styles.content__rightimg}>
                      <img src="./images/layer-33.png" alt="" /></div>
                    <div className={styles.content__rightimg}>
                      <img src="./images/layer-34.png" alt="" /></div>
              </div>
          </div>   
      </Col>
    </Row>
    </>
  )
}
